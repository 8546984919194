<template>
	<div class="puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.berth.portname')" :value="getBerthPort"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.berth.name')" :value="getBerthName"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.berth.berthcode')" :value="getBerthcode"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'berth-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'berth'
		};
	},
	computed: {
		getBerthPort() {
			return this.model && this.model.portname ? this.model.portname : '';
		},
		getBerthName() {
			return this.model && this.model.name ? this.model.name : '';
		},
		getBerthcode() {
			return this.model && this.model.berthcode ? this.model.berthcode : '';
		}
	}
};
</script>
